@import '../styles/theme.less';

.header {
  min-width: 800px;
  height: 60px !important;
  line-height: 60px !important;
  padding-right: 52px !important;
  padding-left: 24px !important;
  display: flex;
  align-items: center;
  background: #131F3E !important;
  position: relative;

  .logo {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    font-family: Trebuchet MS, PingFangSC-Regular, PingFang SC, sans-serif;
    user-select: none;
  }
  

  .trigger {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    color: #899399;
    padding-left: 23px;
  }
}

.contentBody {
  position: relative;
  flex: 1;
  min-width: 800px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #EDEFF4;

  .contentView {
    flex: 1;
    display: flex;
    height: 100%;
    flex-direction: column;

    .content {
      flex: 1;
    }
  }
}

.sider {
  background: rgba(29, 37, 41, 1) !important;
  height: 100%;
  overflow-x: hidden;

  &.collapsedSider {
    flex: 0 0 68px !important;
    min-width: 68px !important;
    width: 68px !important;
  }

  :global(.ant-menu-root) {
    border: unset !important;
    font-weight: 600;
  }

  :global(.ant-menu-item) {
    margin: 0 !important;
    background: rgba(29, 37, 41, 1);
    font-weight: 400;
    height: 48px !important;
    line-height: 48px !important;

    &:after {
      content: unset !important;
    }

    &:active {
      background: rgba(29, 37, 41, 1);
    }
  }

  :global(.ant-menu-root > .ant-menu-item) {
    color: #fff;
    font-weight: 600;
  }

  .subMenu {
    color: rgba(20, 23, 26, 1);
  }

  .fistLevelMenuItem {
    color: rgba(20, 23, 26, 1);
  }

  :global(.ant-menu-item-icon) {
    font-size: 20px !important;
  }

  :global(.ant-menu-item-selected) {
    color: #fff !important;
  }

  :global(.ant-menu-submenu-title) {
    margin: 0 !important;

    &:active {
      background: rgba(29, 37, 41, 1);
    }
  }

  :global(.ant-menu-submenu-title .ant-menu-title-content) {
    margin-left: 12px;
  }

  :global(.ant-menu-sub.ant-menu-inline .ant-menu-title-content) {
    padding-left: 8px;
  }

  /******   菜单右侧箭头样式  *********/

  :global(.ant-menu-submenu-arrow) {
    &:before {
      content: unset;
    }

    &:after {
      width: 0;
      height: 0;
      border-top: 6px solid rgba(170, 184, 194, 1);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-radius: unset;
      background: unset;
    }
  }

  :global(.ant-menu-submenu-inline .ant-menu-submenu-arrow::after) {
    transform: rotate(0deg) translateY(-2px);
  }

  :global(.ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      > .ant-menu-submenu-arrow::after) {
    transform: rotate(180deg) translateY(2px);
  }

  /**************************************************/
}

/*********  collapsed 子菜单的点击、选中、悬浮灯样式  ***********/
:global(.ant-menu-item-only-child) {
  &:hover {
    color: rgba(25, 118, 210, 1) !important;
    background: #e6f7ff;
  }

  &:active {
    color: rgba(25, 118, 210, 1) !important;
    background: #e6f7ff !important;
  }
}

:global(.ant-menu-item-selected.ant-menu-item-only-child) {
  &:hover {
    color: #fff !important;
  }
}

/*********************************/

.footer {
  height: 60px;
  border-top: 1px solid rgba(239, 243, 245, 1);
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  color: rgba(170, 184, 194, 1);
  font-size: 12px;
  line-height: 16px;
  padding: 14px 0;
  margin-top: auto;
}
