@import '~antd/lib/style/core/global.less';
@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
//@import 'styles/theme.less';
//@import './styles/common.less';
//@import './styles/overWrite.less';

#root {
  height: 100vh;
  color: rgba(20, 23, 26, 1);
  box-sizing: border-box;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
}

.ant-layout {
  height: 100%;
  background: #fff;
}

// 解决热更新后页面无法点击问题（被热跟新iframe遮罩层挡住了）
body > iframe {
  width: 0 !important;
  height: 0 !important;
  left: -9999px !important;
  bottom: -9999px !important;
}
